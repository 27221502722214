<template>
  <Layout>
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <router-link
          :to="`/${eleccion}/Secciones/${distrito}/${listaSecciones[0].seccion_id}`"
          class="
            btn btn-secondary btn-link text-center
            font-weight-bold mb-3 mx-auto d-block text-white w-75
          ">
          Sección
          </router-link>

        <h4>
          {{ titulo }} -
          <b class="primario">Detalle del Distrito</b> -
          <span class="primario">Votos por Candidatura</span>
        </h4>

        <p>
          <router-link :to="`/${eleccion}/ENT/VPC`" class="estado-volver">
            Baja California Sur
          </router-link> / <b>
            <span v-if="eleccion != 'A'">
              Distrito {{distrito}}. {{distrito_nombre}}
            </span>
            <span v-else>
              Municipio {{ayuntamiento.municipio_id}}. {{ayuntamiento.municipio_descripcion}}
            </span>
          </b>
        </p>

        <p>
          El total de votos mostrado a nivel Entidad representa la suma
          del voto emitido en territorio Estatal y en el Extranjero.
        </p>

        <p class="mb-5">
          Por representación, los decimales de los porcentajes muestran sólo cuatro digitos.
          No obstante, al considera todos los decimales, suman 100%.
        </p>

        <div class="totalVotos mb-5">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumenDistrito.total_votos_general_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{ resumenDistrito.total_votos_general_porcentaje }}%
          </p>
        </div>

        <div class="votosEnActasContabilizadas">
          <p class="titulo">
            Votos en Actas Contabilizadas
          </p>

          <p>
            Presiona sobre el recuadro blanco y selecciona de una a
            tres opciones para conocer el detalle de la votación.
          </p>
        </div>

        <div class="row">
          <div
            class="col-12 col-md-6 mb-4"
            v-for="(candidato, index) in votosXCandidatoDistritoTarjeta.datos_candidaturas"
            :key="index"
          >
            <div
              class="resumen-voto-candidatura"
              :class="obtenerClaseGanador(candidato)"
            >
              <div class="grilla-datos-candidatura">
                <div class="foto">
                  <!-- <img src="@/assets/avatar-persona.svg" :alt="candidato.nombre"> -->
                  <img
                    :src="candidato.candidato_foto_url"
                    :alt="candidato.nombre"
                    style="max-height:40px;"
                  >
                  <!-- <avatar :colores="colores" /> -->
                </div>
                <div class="nombre">
                  <b>{{candidato.candidato_nombre}} {{candidato.candidato_apellidos}}</b>
                </div>
                <div class="seleccion">
                  <input type="checkbox" v-model="candidato.seleccionado">
                </div>
              </div>
              <div class="grilla-votos mb-2">
                <div class="total text-left">
                  <p class="mb-0 font-weight-bold">
                    Total de votos <br />
                    <span>
                      {{ candidato.candidatura_total_votos_numero }}
                    </span>
                  </p>
                </div>
                <div class="porcentaje text-right">
                  <p class="mb-0 font-weight-bold">
                    <span>
                      {{ candidato.candidatura_total_votos_porcentaje }}%
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="resumenDeLaVotacion mb-5">
          <p class="titulo">
            Resumen de la votación
          </p>

          <ResumenVotacion texto="Candidaturas no registradas"
          :numero="resumenDistrito.total_votos_candidatura_noregistrada_numero"
          :porcentaje="resumenDistrito.total_votos_candidatura_noregistrada_porcentaje" />
          <ResumenVotacion texto="Nulos"
          :numero="resumenDistrito.total_votos_nulos_numero"
          :porcentaje="resumenDistrito.total_votos_nulos_porcentaje" />

          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary float-right" v-b-modal.DetalleEleccionCandidaturasModal
              @click="actualizarDetalleEleccion()">
                Ver detalle
              </button>
            </div>
          </div>
        </div>

        <div class="votosEnActasContabilizadas mb-3">
          <p class="titulo">
            Distribución de votos por candidatura a nivel Distrito
          </p>

          <p>
            La tabla muestra el desglose de votos que cada candidatura presenta en el Distrito,
            indicando los votos recibidos de manera individual y, en su caso,
            los votos recibidos vía coalición.
          </p>

          <a
            href="https://ayuda.ine.mx/2018/PREP/"
            target="__blank"
            rel="noopener noreferrer"
            v-b-modal.ConocePorcentajeParticipacionCiudadana
          >
            <u>
              Conoce cómo se suman los votos para Candidaturas
              de Coalición de acuerdo a la legislatura vigente.
            </u>
          </a>
        </div>

        <div class="row mb-3">
          <distribucion-votos
            v-for="(candidato, index) in distribucion.datos_candidatos"
            :key="index"
            :candidato="candidato"
          />
        </div>

        <div class="votosEnActasContabilizadas">
          <p class="titulo">
            Estadística
            <template v-if="eleccion != 'A'">
              Distrito {{distrito}}. {{ distrito_nombre }}
            </template>
            <template v-else>
              Municipio {{ayuntamiento.municipio_id}}. {{ayuntamiento.municipio_descripcion}}
            </template>
          </p>
        </div>

        <div class="estadisticaEntidad mb-3">
          <h5 class="font-weight-bold mb-3">
            <u>Actas</u>
          </h5>
          <div class="grilla-estadisticas mb-4">
            <div class="esperadas">
              <ProgresoVertical class="mb-3"
                :avance="calcularAvance(estadisticas.actas_esperadas_porcentaje)"
                :color="colorEsperadas"
              />
              <p>
                {{ estadisticas.actas_esperadas_numero }} <br />
                {{ estadisticas.actas_esperadas_porcentaje }}% <br />
                Esperadas
              </p>
            </div>
            <div class="capturadas">
              <ProgresoVertical
                class="mb-3"
                :avance="calcularAvance(estadisticas.actas_capturadas_porcentaje)"
                :color="colorCapturadas"
              />
              <p>
                {{ estadisticas.actas_capturadas_numero }} <br />
                {{ estadisticas.actas_capturadas_porcentaje }}% <br />
                Capturadas
              </p>
            </div>
            <div class="contabilizadas">
              <ProgresoVertical
                class="mb-3"
                :avance="calcularAvance(estadisticas.actas_contabilizadas_porcentaje)"
                :color="colorContabilizadas"
              />
              <p>
                {{ estadisticas.actas_contabilizadas_numero }} <br />
                {{ estadisticas.actas_contabilizadas_porcentaje }}% <br />
                Contabilizadas
              </p>
            </div>
          </div>
        </div>

        <div class="listaNominal mb-5">
          <h5 class="font-weight-bold mb-3">
            Lista Nominal
          </h5>

          <h1 class="text-center font-weight-bold mb-3">
            {{  estadisticas.valor_lista_nominal_actas_contabilizadas_numero }}
          </h1>
          <p class="text-center small mb-4">
            Lista Nominal de Actas Contabilizadas
          </p>

          <h5 class="font-weight-bold mb-3">
            Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas
          </h5>

          <p>
            El porcentaje de Participación Ciudadana se obtiene al multiplicar los
            Votos por 100 y dividir el resultado de esta operación entre el número de
            personas registrado en la Lista Nominal de Actas Contabilizadas.
          </p>

          <p class="text-center font-weight-bold mb-2">
            Porcentaje
          </p>
          <h1 class="text-center font-weight-bold primario display-4">
            {{  estadisticas.valor_lista_nominal_actas_contabilizadas_porcentaje }}%
          </h1>
          <p class="text-center mb-0">
            Porcentaje calculado <b>sin considerar</b> la votación<br />
            en <b>Casillas Especiales.</b>
          </p>
          <p class="text-center mb-2">
            (Total de votos de las Actas Contabilizadas / Lista Nominal) x 100
            = % de Participación Ciudadana.
          </p>
        </div>
      </div>

      <detalle-eleccion
        :resumen="resumenDistrito"
        :votos="votosXCandidatoDistritoTarjeta"
        :esXDistrito="true"
        :key="random"
      />

      <ConocePorcentajeParticipacionCiudadana />
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout';
import ConocePorcentajeParticipacionCiudadana from '@/components/ConocePorcentajeParticipacionCiudadana.vue';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion';
import DistribucionVotos from '@/components/Movil/componentes/DistribucionVotos.vue';
import ProgresoVertical from '@/components/Movil/componentes/ProgresoVertical.vue';
import DetalleEleccion from '@/components/DetalleEleccionCandidaturas.vue';

import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';

export default {
  name: 'VpcDetalleDelDistritoMovil',
  components: {
    Layout,
    ResumenVotacion,
    DistribucionVotos,
    ProgresoVertical,
    DetalleEleccion,
    ConocePorcentajeParticipacionCiudadana,
  },
  data() {
    return {
      random: 0,
    };
  },
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(
        (d) => d.id === this.distrito,
      ).descripcion;
    },
    titulo() {
      if (this.$route.params.eleccion === 'G') { return 'Gubernatura'; }
      if (this.$route.params.eleccion === 'A') { return 'Ayuntamiento'; } return 'Diputaciones';
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    distrito() {
      return this.$route.params.distrito;
    },
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1];
    },
    colores() {
      return obtenerColoresDeAvatar(this);
    },
    colorEsperadas() {
      if (this.$store.state.esOscuro) {
        return 'fd2a71';
      }
      return 'd2127e';
    },
    colorCapturadas() {
      if (this.$store.state.esOscuro) {
        return 'fe9db0';
      }
      return '79144c';
    },
    colorContabilizadas() {
      if (this.$store.state.esOscuro) {
        return 'fec5cd';
      }
      return 'efb5d3';
    },
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    votosXCandidatoDistritoTarjeta() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXCandidatoDistritoTarjeta.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXDistritoTarjeta.distritos[this.$route.params.distrito - 1];
      }
      return this.$store.state.aVotosXCandidatoDistritoTarjeta.municipios[
        this.$route.params.distrito - 1
      ];
    },
    resumenDistrito() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      }
      return this.$store.state.aResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
    },
    distribucion() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gDistribucionVotosCandidaturasDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dDistribucionVotosCandidaturasDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      }
      return this.$store.state.aDistribucionVotosCandidaturasDistrito.municipios[
        this.$route.params.distrito - 1
      ];
    },
    estadisticas() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gEstadisticasNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dEstadisticasNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aEstadisticasNivelDistrito.municipios[
          this.$route.params.distrito - 1
        ];
      }

      return {};
    },
    listaSecciones() {
      if (this.eleccion !== 'A') {
        return this.$store.state.elementosGenerales.datos_secciones
          .distritos[this.distrito - 1]
          .datos_secciones;
      }
      return this.$store.state.elementosGenerales
        .datos_secciones_por_municipio[this.distrito - 1]
        .datos_secciones;
    },
  },
  methods: {
    actualizarDetalleEleccion() {
      this.$forceUpdate();
      this.random = Math.random();
    },
    obtenerClaseGanador(candidato) {
      const votos = this.votosXCandidatoDistritoTarjeta;
      return candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
        ? 'ganador'
        : '';
    },
    calcularAvance(avance) {
      return Math.ceil(parseFloat(avance));
    },
  },
};
</script>

<style lang="scss" scoped>
.ganador {
  border: 3px solid #d2127e;
  .grilla-votos {
    p > span {
      color: #d2127e;
    }
  }
}

.resumen-voto-candidatura {
  background-color: #f5f5f5;
  padding: 10px;

  .grilla-datos-candidatura {
    display: grid;
    grid-template-columns: 75px 1fr 30px;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;

    .foto {
      img {
        max-height: 50px;
        display: block;
        margin: 0 auto;
      }
    }

    .nombre {
      font-size: 16px;
    }
  }

  .grilla-votos, .grilla-distribucion-votos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 30px;
  }

  .grilla-distribucion-votos {
    background-color: #d3d3ec;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    padding: 10px;
  }
}

.btn-link {
  border-radius: 15px;
  background-color: #34344e;
  border-color: transparent;
}
</style>
