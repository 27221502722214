<template>
  <div class="vista-escritorio">
    <Cabecera/>
    <Cuerpo>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h2>{{ eleccion }} - <span>
              <strong v-if="this.$route.params.eleccion !== 'A'">Detalle del Distrito</strong>
              <strong v-else>Detalle por Municipio</strong>
              - Votos por Candidatura</span></h2>
            <h4>
              <strong>
                <router-link :to="`/${this.$route.params.eleccion}/ENT/VPC`" class="estado-volver">
                  Baja California Sur
                </router-link>
              </strong>
              /
              <span v-if="this.$route.params.eleccion !== 'A'">
                Distrito {{distrito}}. {{ distrito_nombre }}
              </span>
              <span v-else>
                Municipio {{this.distrito}}. {{ ayuntamiento.municipio_descripcion }}
              </span>
            </h4>
            <p>
              El total de votos calculado y porcentaje que se muestran, se refieren a los votos
              asentados en las actas PREP hasta el momento. <br />
              Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
              obstante, al considerar todos los decimales, suman 100%.
            </p>
            <h3>Votos en Actas Contabilizadas</h3>

            <div class="row">
              <div
                v-for="(candidato, index) in votos.datos_candidaturas"
                :key="index"
                style="padding:10px;"
              >
                <div
                  class="resumen-voto-candidatura"
                  :class="candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
                    ? 'ganador'
                    : ''"
                  >
                  <div class="grilla-datos-candidatura">
                    <div class="foto" style="text-align:center">
                      <img
                        :src="candidato.candidato_foto_url"
                        :alt="candidato.nombre"
                        style="max-height:40px;"
                      >
                    </div>
                    <div class="nombre text-center mb-4">
                      <b>{{candidato.candidato_nombre}}</b>
                      <br/>
                      <b>{{candidato.candidato_apellidos}}</b>
                    </div>
                  </div>
                  <div class="partidos" style="text-align:center">
                    <ul>
                      <li>
                        <img :src="candidato.candidatura_logo" style="max-height:40px;">
                      </li>
                    </ul>
                  </div>
                  <div class="grilla-resumen" style="background-color:#F4F4F4">
                    <div class="total text-left">
                      <p class="mb-0 font-weight-bold">
                        Votos <br/>
                        <span>
                          {{ candidato.candidatura_total_votos_numero }}
                        </span>
                      </p>
                    </div>
                    <div class="porcentaje text-right">
                      <p class="mb-0 font-weight-bold">
                        <span>
                          {{ candidato.candidatura_total_votos_porcentaje }}%
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <b-form-checkbox
                  class="boton-seleccionar"
                  v-model="candidato.seleccionado"
                  button
                  :class="{ 'seleccionado': candidato.seleccionado }"
                  v-if="!candidato.seleccionado"
                  :disabled="sonTresSeleccionados"
                >
                  Seleccionar
                </b-form-checkbox>
                <b-form-checkbox
                  class="boton-seleccionar"
                  v-model="candidato.seleccionado"
                  button
                  v-else
                  :class="{ 'seleccionado': candidato.seleccionado }"
                >
                  Quitar selección
                </b-form-checkbox>
              </div>
            </div>

            <p class="text-right">
              Da click en <strong>Selecionar</strong> para visualizar el detalle de la votación.
              Puedes seleccionar hasta tres opciones.
            </p>

            <div class="row px-3">
              <div class="col-12 mb-3">
                <button
                  class="btn btn-primary float-right boton-verdetalle"
                  v-b-modal.DetalleEleccionCandidaturasModal
                >
                  Ver detalle
                </button>
              </div>
              <div class="col-8 mb-3 resumen-votacion">
                <h4 class="mb-2">
                  Resumen de la votación
                </h4>
                <div class="grilla-suma">
                  <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      {{ resumenDistrito.total_votos_acumulados_numero }} <br />
                      <span class="porcentaje">
                        {{ resumenDistrito.total_votos_acumulados_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br />
                      <span class="porcentaje">
                        {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                      Nulos <br />
                      {{ resumenDistrito.total_votos_nulos_numero}} <br />
                      <span class="porcentaje">
                        {{ resumenDistrito.total_votos_nulos_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right">
                    <p class="font-weight-bold">
                      Total de votos <br />
                      <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br />
                      <span class="porcentaje">
                        {{ resumenDistrito.total_votos_general_porcentaje }}%
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h4>
              Distribución de votos por candidatura a nivel
              {{ eleccion == "Ayuntamientos" ? "Municipio" : "Distrito"}}
            </h4>

            <p>
              La tabla muestra el desglose de votos que cada candidatura presenta en el
              {{ eleccion == "Ayuntamientos" ? "Municipio" : "Distrito"}},
              indicando los votos recibidos de manera individual y,
              en su caso, los votos recibidos vía Coalición.
              <br />
              <a
                href="https://ayuda.ine.mx/2018/PREP/"
                target="__blank" rel="noopener noreferrer"
                v-b-modal.ConocePorcentajeParticipacionCiudadana
              >
                <u>
                  Conoce cómo se suman los votos para Candidaturas de
                  Coalición de acuerdo a la legislatura vigente.
                </u>
              </a>
            </p>

            <div class="table-responsive my-4">
              <table class="table tabla-distribucion-votos table-bordered table-striped">
                <thead>
                  <tr class="text-center">
                    <th>
                      Candidaturas
                    </th>
                    <th>
                      Distribución de votos por Candidatura
                    </th>
                    <th>
                      Total de votos por Candidatura
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(candidato, index) in distribucion.datos_candidatos"
                    :key="index"
                  >
                    <td>
                      <div>
                        <p
                          class="mb-0 font-weight-bold"
                        >
                          {{candidato.candidato_nombre}} {{candidato.candidato_apellidos}}
                        </p>
                        <img
                          :src="candidato.candidato_foto_url"
                          style="width: 60px;"
                          :alt="candidato.candidato_nombre"
                        >
                      </div>
                    </td>
                    <td v-if="Object.keys(candidato.datos_votos).length === 1">
                      <div
                        v-for="(partido, key) in  candidato.datos_votos"
                        class="grilla-datos-candidatura mb-2"
                        :key="key"
                      >
                        <div class="float-right">
                          <img
                            :src="partido.logo_integrante"
                            style="height:30px; margin:10px;"
                            :alt="obtenerAlt(partido.logo_integrante)"
                          >
                          <span
                            class="font-weight-bold"
                            style="margin:5px;"
                          >
                            {{ partido.votos_integrante }}
                          </span>
                          <font-awesome-icon
                            v-if="key !== Object.keys(candidato.datos_votos).length - 1"
                            icon="plus-circle"
                            style="margin-left:10px;margin-right:10px;"
                          />
                        </div>
                      </div>
                    </td>
                    <td v-else>
                      <div
                        v-for="(partido, key) in  candidato.datos_votos"
                        class="grilla-datos-candidatura mb-2"
                        :key="key"
                      >
                        <div class="float-left">
                          <img
                            :src="partido.logo_integrante"
                            style="height:30px; margin:10px;" alt=""
                          >
                          <span
                            class="font-weight-bold"
                            style="margin:5px;"
                          >
                            {{ partido.votos_integrante }}
                          </span>
                          <font-awesome-icon
                            v-if="key !== Object.keys(candidato.datos_votos).length - 1"
                            icon="plus-circle"
                            style="margin-left:10px;margin-right:10px;"
                          />
                        </div>
                      </div>
                    </td>
                    <td class="votos">
                      <div class="row">
                        <div class="col-1">
                          <font-awesome-icon
                            icon="equals"
                            style="margin-left:10px;margin-right:10px;"
                          />
                        </div>
                        <div class="col-10">
                          <p class="mb-0">
                            {{ candidato.candidatura_total_votos_numero }}
                          </p>
                        </div>
                      </div>
                      <p class="titulo">Total de votos por Candidatura</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h3>
              Estadística
              <span v-if="eleccion !== 'Ayuntamientos'">Distrito {{ distrito }}. {{ distrito_nombre }}</span>
              <span v-else>Municipio {{ distrito }}. {{ ayuntamiento.municipio_descripcion }}</span>
            </h3>

            <EstadisticasInternas :detalle="estadisticas" />
          </div>
        </div>
      </div>

      <div class="text-center mt-3">
        <p>
          Los resultados presentados tienen un carácter informativo y no son definitivos, por tanto,
          no tienen efectos jurídicos. <br />
          Con base en la Ley Federal del Derecho de Autor queda prohíbida cualquier
          modificación al diseño de este sitio. <br />
          La modificación y/o destrucción de la información y/o contenido total o
          parcial de este sitio, es delito federal de acuerdo al Código Penal Federal.
        </p>
      </div>
    </Cuerpo>
    <pie-de-pagina />

    <detalle-eleccion-candidaturas
      :resumen="resumenDistrito"
      :votos="votos"
      :esXDistrito="true"
    />
    <compartir />
  </div>
</template>

<script>
import Cabecera from '@/components/Escritorio/componentes/Cabecera';
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo';
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';
import DetalleEleccionCandidaturas from '@/components/DetalleEleccionCandidaturas.vue';
import Compartir from '@/components/Compartir.vue';
import EstadisticasInternas from '@/components/Escritorio/componentes/EstadisticasInternas.vue';
import PieDePagina from '../../componentes/PieDePagina.vue';

export default {
  name: 'VotosPorCandidaturasEscritorio',
  components: {
    Cabecera,
    Cuerpo,
    DetalleEleccionCandidaturas,
    EstadisticasInternas,
    Compartir,
    PieDePagina,
  },
  data() {
    return {
      random: 0,
      votos: {},
    };
  },
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(d => d.id == this.distrito).descripcion;
    },
    eleccion() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (this.$route.params.eleccion === 'D') {
        return 'Diputaciones';
      } if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamientos';
      }
      return '';
    },
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1];
    },
    distrito() {
      return this.$route.params.distrito; 
    },
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(d => d.id == this.distrito).descripcion;
    },
    estadisticas() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gEstadisticasNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dEstadisticasNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aEstadisticasNivelDistrito.municipios[this.$route.params.distrito - 1];
      }
      return {};
    },
    resumenDistrito() {
      // Enviar el índice del Distrito
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      }
      return {};
    },
    colores() {
      return obtenerColoresDeAvatar(this);
    },
    votosXCandidatoDistritoTarjeta() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXCandidatoDistritoTarjeta.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXDistritoTarjeta.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosXCandidatoDistritoTarjeta.municipios[this.$route.params.distrito - 1];
      }
      return {};
    },
    distribucion() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gDistribucionVotosCandidaturasDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dDistribucionVotosCandidaturasDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aDistribucionVotosCandidaturasDistrito.municipios[this.$route.params.distrito - 1];
      }
      return {};
    },
    sonTresSeleccionados() {
      let seleccionados = 0;
      this.votos.datos_candidaturas.map((candidatura) => {
        if (candidatura.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }
        return null;
      });

      return seleccionados === 3;
    },
  },
  methods: {
    onCheckboxChange() {
      this.$forceUpdate();
      this.random = Math.random();
    },
    obtenerAlt(ruta) {
      return ruta.replace('/emblemas/', '')
        .replace('.jpeg', '');
    },
    setearVotos() {
      const niveles = {
        G: 'gVotosXCandidatoDistritoTarjeta',
        A: 'aVotosXCandidatoDistritoTarjeta',
        D: 'dVotosXCandidatoDistritoTarjeta',
      };

      const { distrito, eleccion } = this.$route.params;

      const estado = this.$store.state[
        niveles[eleccion]
      ];

      if (eleccion === 'A') {
        this.votos = estado?.municipios[distrito - 1];
      } else {
        this.votos = estado?.distritos[distrito - 1];
      }
    },
  },
  created() {
    this.setearVotos();
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.setearVotos();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.resumen-voto-candidatura {
  min-height: 90%;
  display: grid;
  grid-template-rows: 1fr;
  padding: 3px;
  border: 1px solid #181A2F;
  //height: 25vh;
  max-width: 150px;
  .grilla-datos-candidatura {
    margin: 10px;
    .foto > svg {
      max-width: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
  }
  .grilla-resumen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 15px;
    margin-top:10px;
  }
}

.ganador {
  border-color: #D2127E;
  border-width: 3px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  .total {
    p > span {
      color: #D2127E;
    }
  }
}

.boton-seleccionar {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #181A2F;
  border-color: transparent;

  label {
    border: none;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.seleccionado {
  background-color: lighten(#181A2F, 10%);
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #34344E;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #D2127E;
      }
    }
  }
}

.tabla-distribucion-votos {
  tbody {
    tr {
      vertical-align: top;
      td {
        svg {
          max-width: 50px;
        }

        &:nth-child(2n) {
          width: 50%;
        }
      }
      .votos {
        text-align: center;
        background: transparent;
        p {
          font-size: 1.5rem !important;
          font-weight: bold !important;
        }
        .titulo{
          font-size: 1rem !important;
          font-weight: bold !important;
        }
        svg{
            background-color: black;
            color: white;
            padding: 5px;
            border-radius: 50px;
            width: 20px;
            font-size: 20px;
        }
      }
    }
  }
}

.boton-verdetalle {
  background-color: #181A2F;
  border-color: transparent;
}

</style>
