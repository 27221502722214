<template>
  <div>
    <VpcDetalleDelDistritoMovil v-if="anchoPantalla < 992" />
    <VpcDetalleDelDistritoEscritorio v-else />
  </div>
</template>

<script>
import VpcDetalleDelDistritoMovil from '@/components/Movil/vistas/VotosPorCandidaturas/DetalleDelDistrito.vue';
import VpcDetalleDelDistritoEscritorio from '@/components/Escritorio/vistas/VotosPorCandidatura/DetalleDelDistrito.vue';

export default {
  name: 'DetalleDelDistrito',
  components: {
    VpcDetalleDelDistritoMovil,
    VpcDetalleDelDistritoEscritorio,
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Entidad - Votos Por Candidaturas - Detalles del Distrito',
  },
  computed: {
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
